import { Database } from "@nozbe/watermelondb"
import { itemAPI } from "storage/api/items/Item"
import { itemRepository } from "../repository"
import { DEFAULT_CARD } from "./data"
import { createAssets } from "./editorBlocks"

const initializeDefaultCard = async (db: Database) => {
    const defaultCard = await itemRepository.getByName(db, DEFAULT_CARD.name)
    if (defaultCard) return

    const itemModel = await itemAPI.saveItemApi(db, DEFAULT_CARD, true, { isExpanded: true })
    if (!itemModel) return
    await itemModel.saveDeep()
    await createAssets(db, itemModel)
}

export const itemHelper = { initializeDefaultCard }
