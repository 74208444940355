import CloseIcon from "@mui/icons-material/Close"
import ModeIcon from "@mui/icons-material/Mode"
import { alpha, Box, useTheme } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { sentry } from "@recall/common"
import { findNodePath, focusEditor, PlateElement, PlateElementProps } from "@udecode/plate-common"
import { ItemPopper } from "components/shared/poppers/ItemPopper"
import { useHoverDelay } from "hooks/useHoverDelay"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsDarkMode } from "hooks/useThemeMode"
import React, { useEffect, useState } from "react"
import { Range, Transforms } from "slate"
import { ConnectionModel, ItemModel } from "storage/watermelon/models"
import { connectionRepository } from "storage/watermelon/repository"
import { ActionButton } from "../../../../shared/buttons/ActionButton"
import { MyReferenceElement, useMyEditorState } from "../types"

export const REFERENCE_DIV_ID = "id-reference"

interface ReferenceElementProps extends PlateElementProps<MyReferenceElement[]> {
    readOnly: boolean
}

export const ReferenceElement: React.FC<React.PropsWithChildren<ReferenceElementProps>> = ({
    children,
    element,
    readOnly,
    ...props
}) => {
    const db = useDatabase()
    const [item, setItem] = useState(null)

    const isMobile = useIsMobile()
    const theme = useTheme()
    const editor = useMyEditorState()

    const isCollapsed = Boolean(editor.selection && Range.isCollapsed(editor.selection))
    const canShowPopper = editor.selection === null || isCollapsed
    const isDarkMode = useIsDarkMode()

    const { isOpen, setIsOpen, handleMouseEnter, handleMouseLeave } = useHoverDelay(300)

    useEffect(() => {
        connectionRepository
            .get(db, element.connectionId)
            .then((connection: ConnectionModel) => (connection ? connection.to.fetch() : null))
            .then((item: ItemModel) => setItem(item))
            .catch((e) => sentry.captureException(e))
    }, [db, element.connectionId])

    const handleClickEdit = () => {
        setIsOpen(false)
        const text = element.children?.[0]?.text

        if (text) {
            const path = findNodePath(editor, element)

            if (path) {
                path.push(0)
                focusEditor(editor)
                Transforms.select(editor, { path: path, offset: text.length })
            }
        }
    }

    const actionButtonsStart = (
        <ActionButton onClick={handleClickEdit} tooltip={"Edit text"}>
            <ModeIcon fontSize={"inherit"} />
        </ActionButton>
    )

    const actionButtonsEnd = (
        <ActionButton onClick={() => setIsOpen(false)} tooltip={"Close"}>
            <CloseIcon fontSize={"inherit"} />
        </ActionButton>
    )

    const textColor = isDarkMode ? "#f5d471" : "#3366cc"

    return (
        <PlateElement
            id={REFERENCE_DIV_ID}
            as="span"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            element={element}
            {...props}
        >
            <Box component="span" id={element.editorBlockId + element.connectionId}>
                <ItemPopper
                    hidePin
                    item={item}
                    actionButtonsStart={isMobile ? actionButtonsStart : undefined}
                    actionButtonsEnd={isMobile ? actionButtonsEnd : undefined}
                    open={isOpen && canShowPopper && Boolean(item)}
                    connectionId={element.connectionId}
                    readOnly={readOnly}
                >
                    <Box
                        component="span"
                        sx={{
                            cursor: "pointer",
                            outline: "none",
                            color: textColor,
                            "&:hover": {
                                color: alpha(textColor, 0.8),
                            },
                            "& ::selection": {
                                color: isDarkMode && "primary.dark",
                            },
                        }}
                    >
                        {children}
                    </Box>
                </ItemPopper>
            </Box>
        </PlateElement>
    )
}
