import { DatabaseProvider } from "@nozbe/watermelondb/react"
import { posthogService, sentry } from "@recall/common"
import { ErrorBoundary } from "components/shared/errors/ErrorBoundary"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import "typeface-montserrat"
import App from "./App"
import store, { persistor } from "./storage/redux/store"
import { database } from "./storage/watermelon/database"

posthogService.init({ autocapture: false, capture_pageview: true })
sentry.init("web", "1.0.152")

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript

const Root = () => {
    return (
        <Router>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <DatabaseProvider database={database}>
                        <ErrorBoundary>
                            <App />
                        </ErrorBoundary>
                    </DatabaseProvider>
                </PersistGate>
            </Provider>
        </Router>
    )
}

root.render(<Root />)
