import { Box } from "@mui/material"
import { OrderItemsDropdown } from "components/ItemsPage/components/OrderItemsDropdown"
import { FC, memo } from "react"
import { DrawerButton } from "../Drawer/DrawerButton"
import { AddButton } from "./AddButton"
import { BookmarksImportButton } from "./BookmarksImportButton"
import { OfflineStatus } from "./OfflineStatus"

interface Props {
    showItemsActions?: boolean
}

const HeaderComp: FC<Props> = ({ showItemsActions = false }) => {
    return (
        <Box
            display="flex"
            flexWrap="wrap"
            position="relative"
            alignItems="center"
            gap={1}
            mb={2}
            minHeight={showItemsActions && 60}
        >
            <DrawerButton />
            <OfflineStatus />
            <BookmarksImportButton />
            <Box flexGrow={1} />

            {showItemsActions && (
                <>
                    <AddButton />
                    <OrderItemsDropdown />
                </>
            )}
        </Box>
    )
}

export const Header = memo(HeaderComp)
