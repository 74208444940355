import { ItemApi } from "@recall/common"

export const DEFAULT_CARD: ItemApi = {
    name: "Welcome to Recall",
    isReference: false,
    isSaved: false,
    language: "en",
    type: {
        name: "Productivity",
        display: "Productivity",
        pluralDisplay: "Productivity",
        wikidataId: "",
        parentName: null,
        userCreated: false,
        genealogy: [
            {
                name: "Technology",
                display: "Technology",
                pluralDisplay: "Technology",
                wikidataId: "",
                parentName: null,
                userCreated: false,
                genealogy: [
                    {
                        name: "Thing",
                        display: "Thing",
                        pluralDisplay: "Thing",
                        wikidataId: "",
                        parentName: null,
                        genealogy: null,
                        userCreated: false,
                    },
                ],
            },
            {
                name: "Thing",
                display: "Thing",
                pluralDisplay: "Thing",
                wikidataId: "",
                parentName: null,
                genealogy: null,
                userCreated: false,
            },
        ],
    },
    description: "Product overview",
    editorBlocks: [],
    images: [
        {
            urlOriginal:
                "https://storage.googleapis.com/recall-public/static_assets/images/recall-product-overview.png",
            urlThumbnail:
                "https://storage.googleapis.com/recall-public/static_assets/images/recall-product-overview.png",
            url_320:
                "https://storage.googleapis.com/recall-public/static_assets/images/recall-product-overview.png",
            url_1024:
                "https://storage.googleapis.com/recall-public/static_assets/images/recall-product-overview.png",
            caption: "Product overview",
        },
    ],
    sources: [
        {
            name: "website",
            identifier: "https://www.youtube.com/watch?v=ImS-Ry44Uz8",
        },
    ],
    tags: [],
    links: [
        {
            item: {
                name: "Knowledge base",
                isReference: true,
                isSaved: true,
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    parentName: null,
                    genealogy: null,
                    userCreated: false,
                },
                description: "Information repository with multiple applications",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Information repository with multiple applications",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Knowledge_base",
                    },
                    {
                        name: "website",
                        identifier: "https://en.wikipedia.org/wiki/Knowledge_base",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q593744",
                    },
                ],
                tags: [],
                language: "en",
            },
            property: null,
            slug: "Knowledge_base",
            mention_texts: ["Knowledge base", "knowledge base"],
        },
        {
            item: {
                name: "Knowledge graph",
                isReference: true,
                isSaved: true,
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    parentName: null,
                    genealogy: null,
                    userCreated: false,
                },
                description:
                    "A Knowledge Graph is a structured representation of information that models relationships between concepts, entities, or data points.",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/5/52/Conceptual_Diagram_-_Example.svg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/100px-Conceptual_Diagram_-_Example.svg.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/320px-Conceptual_Diagram_-_Example.svg.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/1024px-Conceptual_Diagram_-_Example.svg.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "A Knowledge Graph is a structured representation of information that models relationships between concepts, entities, or data points.",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/5/52/Conceptual_Diagram_-_Example.svg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/100px-Conceptual_Diagram_-_Example.svg.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/320px-Conceptual_Diagram_-_Example.svg.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Conceptual_Diagram_-_Example.svg/1024px-Conceptual_Diagram_-_Example.svg.png",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Knowledge_graph",
                    },
                    {
                        name: "website",
                        identifier: "https://en.wikipedia.org/wiki/Knowledge_graph",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q33002955",
                    },
                ],
                tags: [],
                language: "en",
            },
            property: null,
            slug: "Knowledge_graph",
            mention_texts: ["Knowledge graph", "knowledge graph"],
        },
        {
            item: {
                name: "Spaced repetition",
                isReference: true,
                isSaved: true,
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    parentName: null,
                    genealogy: null,
                    userCreated: false,
                },
                description: "Learning technique performed with flashcards",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/8/82/Leitner_system_alternative.svg",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/100px-Leitner_system_alternative.svg.png",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/320px-Leitner_system_alternative.svg.png",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/1024px-Leitner_system_alternative.svg.png",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "Learning technique performed with flashcards",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/8/82/Leitner_system_alternative.svg",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/100px-Leitner_system_alternative.svg.png",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/320px-Leitner_system_alternative.svg.png",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Leitner_system_alternative.svg/1024px-Leitner_system_alternative.svg.png",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Spaced_repetition",
                    },
                    {
                        name: "website",
                        identifier: "https://en.wikipedia.org/wiki/Spaced_repetition",
                    },
                    {
                        name: "wikidata",
                        identifier: "Q1095869",
                    },
                ],
                tags: [],
                language: "en",
            },
            property: null,
            slug: "Spaced_repetition",
            mention_texts: ["Spaced Repetition", "Spaced repetition"],
        },
        {
            item: {
                name: "Testing effect",
                isReference: true,
                isSaved: false,
                type: {
                    name: "Thing",
                    display: "Thing",
                    pluralDisplay: "Thing",
                    wikidataId: "",
                    parentName: null,
                    genealogy: null,
                    userCreated: false,
                },
                description: "",
                editorBlocks: [
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "image",
                                urlOriginal:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                                urlThumbnail:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                                url_320:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                                url_1024:
                                    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                                caption: "",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "editor-block",
                        children: [
                            {
                                type: "paragraph",
                                children: [
                                    {
                                        text: "memory effect in educational psychology",
                                        bold: null,
                                    },
                                ],
                            },
                        ],
                    },
                ],
                images: [
                    {
                        urlOriginal:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                        urlThumbnail:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                        url_320:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                        url_1024:
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Anki_flashcard_question_and_answer_math.gif/100px-Anki_flashcard_question_and_answer_math.gif",
                        caption: "",
                    },
                ],
                sources: [
                    {
                        name: "wikipedia",
                        identifier: "Testing_effect",
                    },
                ],
                tags: [],
                language: "en",
            },
            slug: "Testing_effect",
            mention_texts: ["Active Recall"],
        },
    ],
    markdown: `
This is an example of a Recall Summary Card, highlighting our core features to help you get the most out of your experience with Recall.  

Watch the [3-minute video](https://www.youtube.com/watch?v=ImS-Ry44Uz8) for a product overview.

## Get Data into Recall [00:00:47](https://www.youtube.com/watch?v=ImS-Ry44Uz8&t=47s)

You can input content into Recall in several ways, each creating a "Recall Summary Card" like the one you see now:

- **Pasting a URL**: Click “New” in the top-right corner of the Home page and paste the URL of any online content.
- **Mobile App**: Use the mobile app ([App Store](https://apps.apple.com/us/app/recall-summarize-save/id6445893722)/[Google Play](https://play.google.com/store/apps/details?id=com.recall.wiki)) to paste URLs or share content directly with the app.
- **Browser Extension**: Don't miss this step! Install and pin the Recall Browser Extension ([Chrome](https://chromewebstore.google.com/detail/recall-summarize-anything/ldbooahljamnocpaahaidnmlgfklbben)/[Firefox](https://addons.mozilla.org/en-US/firefox/addon/getrecall/)) to summarize any online content.
- **Additional Options**: Use the dropdown menu next to the "New" button to import bookmarks, upload PDFs or create a card from scratch.

## Customize the Recall Summary Card [00:01:39](https://www.youtube.com/watch?v=ImS-Ry44Uz8&t=99s)

All your content in Recall is fully editable, including the option to add or change images.

- **Automatic Categorization**: Tags categorize content saved in Recall. Summary cards are automatically tagged based on the content's topic and existing tags are reused to ensure consistency in your knowledge base.
- **Customize Your Tags**: You can create new tags and nest them under existing ones using the "/" character. This can be done in the top left corner of a card or in the left panel's tag list. Easily drag and drop cards and tags to organize your knowledge base.
- **Entity Extraction**: Entities are keywords with high relevance, automatically extracted and converted into their own cards. These entities form the foundation of your knowledge graph. At the bottom of a summary card, entities are grouped with a number indicating how often they appear in your knowledge base. They are also referenced in the summary, where they can be clicked, expanded, and customized.
- **Create Your Own Entities and Links**:
 - Highlight text in your summary and click the lightning bolt to link it to an entity.
 - Type "/" in the card's body to create links to entities or other cards. For custom entities, select "create empty card."

## Review Content [00:02:03](https://www.youtube.com/watch?v=ImS-Ry44Uz8&t=123s)

Most people will likely forget 90% of the content they consume within a week - this is just our natural forgetting curve. The Recall Review was built to offset this forgetting curve through reinforced understanding and retention.

- **Single Card Review**: Click 'Generate Questions' in the card menu (three dots in the top-right of the card) to create review questions.
- **Scheduled Review**: Cards with generated questions are added to a schedule using Spaced Repetition and Active Recall to resurface content tailored to your learning curve.

## Graph View [00:02:46](https://www.youtube.com/watch?v=ImS-Ry44Uz8&t=146s)

The foundation of Recall is its knowledge graph, where all extracted entities are interconnected. The Graph View visually represents this structure, offering a bird's-eye view of your knowledge base.

- **Single Card Exploration**: Click the graph icon on any card to see its connections.
- **Topic-Based Exploration**: Click “Graph” in the top left of the knowledge base and select a topic to filter your graph.

## Key Links

- Be a part of our Recall community. [Join our Discord channel](https://discord.com/invite/KDgvbcNpt9).
- Need help? [Email us: support@getrecall.ai](mailto:support@getrecall.ai).
- [Check out our docs](https://docs.getrecall.ai/) for more in-depth tutorials.
- [Check out our summary library](https://www.getrecall.ai/summaries) for trending new content.
- Have a feature request? [Submit it!](https://feedback.getrecall.ai/feature-requests)
`,
}
