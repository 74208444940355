import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote"
import { ELEMENT_CODE_BLOCK, ELEMENT_CODE_LINE } from "@udecode/plate-code-block"
import { TDescendant, TElement, TText, Value, getPluginType } from "@udecode/plate-common"
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
} from "@udecode/plate-heading"
import { ELEMENT_HR } from "@udecode/plate-horizontal-rule"
import { ELEMENT_OL, ELEMENT_UL } from "@udecode/plate-list"

import { isYoutubeVideo } from "@recall/common"
import { ELEMENT_LINK } from "@udecode/plate-link"
import { ELEMENT_IMAGE } from "@udecode/plate-media"
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph"
import { RemarkElementRules, remarkTransformNode } from "@udecode/plate-serializer-md"
import { dates } from "utils/dates"
import { ELEMENT_REFERENCE } from "../plugins/reference"
import { YOUTUBE_TIMESTAMP } from "../plugins/youtube-timestamp"
import { MdastNode, RemarkPluginOptions } from "./types"

export const remarkTransformElementChildren = <V extends Value>(
    node: MdastNode,
    options: RemarkPluginOptions<V>
): TDescendant[] => {
    const { children } = node
    if (!children?.length) return [{ text: "" }]

    return children.flatMap((child) => remarkTransformNode(child, options))
}

export const remarkDefaultElementRules: RemarkElementRules<Value> = {
    heading: {
        transform: (node, options) => {
            const headingType = {
                1: ELEMENT_H1,
                2: ELEMENT_H2,
                3: ELEMENT_H3,
                4: ELEMENT_H4,
                5: ELEMENT_H5,
                6: ELEMENT_H6,
            }[node.depth ?? 1]

            return {
                type: getPluginType(options.editor, headingType),
                children: [...remarkTransformElementChildren(node, options), { text: "" }],
            }
        },
    },
    list: {
        transform: (node, options) => {
            return {
                type: getPluginType(options.editor, node.ordered ? ELEMENT_OL : ELEMENT_UL),
                children: remarkTransformElementChildren(node, options),
            }
        },
    },
    listItem: {
        transform: (node, options) => {
            const indent = node?.position?.start?.column || 1
            let children = remarkTransformElementChildren(node, options)

            if (children?.length === 1 && children[0]?.children) {
                children = children[0].children as any
            }

            return {
                type: getPluginType(options.editor, ELEMENT_PARAGRAPH),
                children,
                indent,
                listStyleType: "disc",
            }
        },
    },
    paragraph: {
        transform: (node, options) => {
            const children = [...remarkTransformElementChildren(node, options), { text: "" }]

            const paragraphType = getPluginType(options.editor, ELEMENT_PARAGRAPH)
            const splitBlockTypes = new Set([getPluginType(options.editor, ELEMENT_IMAGE)])

            const elements: TElement[] = []
            let inlineNodes: TDescendant[] = []

            const flushInlineNodes = () => {
                if (inlineNodes.length > 0) {
                    elements.push({
                        type: paragraphType,
                        children: inlineNodes,
                    })

                    inlineNodes = []
                }
            }

            children.forEach((child) => {
                const { type } = child

                if (type && splitBlockTypes.has(type as string)) {
                    flushInlineNodes()
                    elements.push(child as TElement)
                } else {
                    inlineNodes.push(child)
                }
            })

            flushInlineNodes()

            return elements
        },
    },
    link: {
        transform: (node, options) => {
            const text = node?.children?.[0]?.value

            if (dates.isTimeFormat(text) && node.url && isYoutubeVideo(node.url)) {
                return {
                    type: YOUTUBE_TIMESTAMP,
                    url: node.url,
                    children: remarkTransformElementChildren(node, options),
                }
            }

            if (["https", "mailto"].some((startFormat) => node.url.startsWith(startFormat)))
                return {
                    type: getPluginType(options.editor, ELEMENT_LINK),
                    url: node.url,
                    children: remarkTransformElementChildren(node, options),
                }

            return {
                type: ELEMENT_REFERENCE,
                connectionId: node.url,
                children: remarkTransformElementChildren(node, options),
            }
        },
    },
    image: {
        transform: (node, options) => ({
            type: getPluginType(options.editor, ELEMENT_IMAGE),
            children: [{ text: "" } as TText],
            url: node.url,
            width: 400,
            caption: [{ text: node.alt } as TText],
        }),
    },
    blockquote: {
        transform: (node, options) => {
            return {
                type: getPluginType(options.editor, ELEMENT_BLOCKQUOTE),
                children: node.children!.flatMap((paragraph) =>
                    remarkTransformElementChildren(paragraph, options)
                ),
            }
        },
    },
    code: {
        transform: (node, options) => ({
            type: getPluginType(options.editor, ELEMENT_CODE_BLOCK),
            lang: node.lang ?? undefined,
            children: (node.value || "").split("\n").map((line) => ({
                type: getPluginType(options.editor, ELEMENT_CODE_LINE),
                children: [{ text: line } as TText],
            })),
        }),
    },
    thematicBreak: {
        transform: (node, options) => ({
            type: getPluginType(options.editor, ELEMENT_HR),
            children: [{ text: "" } as TText],
        }),
    },
}
