import { useDatabase } from "@nozbe/watermelondb/react"
import { sentry } from "@recall/common"
import { debounce, groupBy, keyBy, map } from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { RootState } from "storage/redux/rootReducer"
import { ItemTagModel, TagModel } from "storage/watermelon/models"
import { ROOT_TAG_ID, itemRepository, tagRepository } from "storage/watermelon/repository"
import { SearchItem, filterItems } from "../helpers/searchItems"
import { useUntaggedItems } from "./useUntaggedItems"

export interface Tag {
    id: string
    children: string[]
    name: string
    isReference?: boolean
    parentId?: string
    isSaved: boolean
    items: SearchItem[]
}

export type GroupedTags = Record<Tag["id"], Tag>

export const RERENDER_TAGS_EVENT = "rerender-tags-event"

export const useGroupedTags = () => {
    const db = useDatabase()
    const [tags, setTags] = useState<GroupedTags>({})
    const [searchText, setSearchText] = useState("")
    const [searchedTags, setSearchedTags] = useState<GroupedTags>({})
    const { untaggedItems } = useUntaggedItems({ searchText })

    const isLoaded = useRef(false)

    const includeReferences = useSelector(
        (state: RootState) => state.drawer.typeSection.inlcudeReferences
    )

    const groupTagsWithParentByTagId = (
        tags: TagModel[],
        groupedItemsByTagId: Record<TagModel["id"], SearchItem[]>
    ): [GroupedTags, string[]] => {
        const tagsWithoutParent: string[] = []
        const acc: GroupedTags = {}

        for (const tag of tags) {
            acc[tag.id] = {
                children: [],
                isReference: tag.isReference,
                name: tag.name,
                id: tag.id,
                isSaved: tag.isSaved,
                items: groupedItemsByTagId?.[tag.id] || [],
                parentId: tag.parent.id,
            }
        }

        for (const tag of tags) {
            if (acc[tag.parent.id]) acc[tag.parent.id].children.push(tag.id)
            else if (tag.id !== ROOT_TAG_ID) {
                tagsWithoutParent.push(tag.id)
                acc[ROOT_TAG_ID].children.push(tag.id)
            }
        }

        return [acc, tagsWithoutParent]
    }

    const groupItemsByTagId = async (itemTags: ItemTagModel[]) => {
        const groupedItemTags = groupBy(itemTags, "tag.id")
        const itemIds = new Set(map(itemTags, "item.id"))
        const groupedItem: Record<string, SearchItem[]> = {}

        const items = includeReferences
            ? await itemRepository.getAll(db)
            : await itemRepository.getQuery(db, includeReferences).fetch()
        const searchItems = await Promise.all(
            items
                .filter((item) => itemIds.has(item.id))
                .map((item) => ({ item, image: item.image }))
        )
        const groupedSearchItems = keyBy(searchItems, "item.id")
        for (const tagId of Object.keys(groupedItemTags)) {
            groupedItem[tagId] = groupedItemTags[tagId]
                .map((itemTag) => groupedSearchItems[itemTag.item.id])
                .filter(Boolean)
        }
        return groupedItem
    }
    // eslint-disable-next-line
    const getTags = useCallback(async () => {
        const tags = includeReferences
            ? await tagRepository.getAll(db)
            : await tagRepository.getAllWithoutReferences(db)

        const itemTags = await tagRepository.getItemTagsByTagIds(db, map(tags, "id"))
        const groupedItemsByTagId = await groupItemsByTagId(itemTags)
        const [groupedTags, tagsWithoutParent]: [GroupedTags, string[]] =
            groupTagsWithParentByTagId(tags, groupedItemsByTagId)
        isLoaded.current = true
        setTags(groupedTags)

        for (const tagId of tagsWithoutParent) {
            await tagRepository.updateParent(db, ROOT_TAG_ID, tagId)
        }
    }, [includeReferences])

    const fetchTags = useCallback(
        debounce(async () => {
            await getTags()
        }),
        [getTags]
    )

    const refetchTags = () => {
        if (isLoaded.current) {
            fetchTags()
        }
    }

    useEffect(() => {
        isLoaded.current = false
        const subscription = itemRepository.observeCount(db).subscribe(refetchTags)
        return () => subscription.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, includeReferences])

    useEffect(() => {
        const subscription = tagRepository.observeCount(db).subscribe(fetchTags)

        return () => subscription.unsubscribe()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, includeReferences])

    useEffect(() => {
        document.addEventListener(RERENDER_TAGS_EVENT, fetchTags)

        return () => {
            document.removeEventListener(RERENDER_TAGS_EVENT, fetchTags)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, includeReferences])

    // eslint-disable-next-line
    const debouncedSetSearchText = useCallback(debounce(setSearchText, 200), [])

    useEffect(() => {
        filterSearchResult()
    }, [searchText, tags])

    const filterSearchResult = async () => {
        if (!Object.keys(tags).length && !Object.keys(searchedTags).length) {
            return
        }

        if (!searchText) {
            setSearchedTags(tags)
            return
        }

        const acc = {}
        for (const tagId of Object.keys(tags)) {
            const tag = tags[tagId]

            if (tag.id === ROOT_TAG_ID) {
                acc[tagId] = tag
                continue
            }
            const tagIncludesSearch = tag.name.toLowerCase().includes(searchText.toLowerCase())
            const items = await filterItems(tag.items, searchText)

            if (!tagIncludesSearch && !items.length) continue

            acc[tagId] = {
                ...tag,
                items,
            }

            const parentIds = [tag.parentId]
            let i = 0
            while (i < parentIds.length) {
                const parent = tags[parentIds[i]]

                if (parent?.parentId) parentIds.push(parent.parentId)
                i++
            }

            for (const parentId of parentIds) {
                if (!tags[parentId]) {
                    sentry.captureMessage(
                        `Parent with id ${parentId} is missing, child is ${tagId}`
                    )
                    continue
                }
                if (!acc?.[parentId]?.items?.length) {
                    const items = await filterItems(tags[parentId].items, searchText)
                    acc[parentId] = {
                        ...tags[parentId],
                        items,
                    }
                }
            }
        }

        setSearchedTags(acc)
    }

    return useMemo(
        () => ({
            tags: searchedTags,
            setSearchText: debouncedSetSearchText,
            searchText,
            untaggedItems,
            isEmpty: untaggedItems.length === 0 && Object.keys(tags).length === 1,
        }),
        // eslint-disable-next-line
        [searchedTags, untaggedItems]
    )
}
