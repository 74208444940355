import { ChevronLeft, Clear, Search } from "@mui/icons-material"
import { Box, IconButton, InputBase, SxProps, Theme, Toolbar } from "@mui/material"
import { useIsMobile } from "hooks/useIsMobile"
import { FC, memo, useState } from "react"
import { useDispatch } from "react-redux"
import { SET_DRAWER_OPEN } from "storage/redux/drawer/actionTypes"
import { ReferencesSwitch } from "./ReferencesSwitch"

interface Props {
    search: (searchText: string) => void
    hideCloseButton: boolean
}

const TagsTreeSearchComponent: FC<Props> = ({ search, hideCloseButton }) => {
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState("")
    const isMobile = useIsMobile()

    const handleSearch = (value: string) => {
        setSearchValue(value)
        search(value)
    }

    const handleClear = () => {
        setSearchValue("")
        search("")
    }

    const handleDrawerClose = () => {
        dispatch({ type: SET_DRAWER_OPEN, payload: false })
    }

    return (
        <>
            <Toolbar />
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                p={0.5}
                pt={0.8}
            >
                <Box sx={styles.wrapper}>
                    <Box sx={styles.icon}>
                        <Search />
                    </Box>
                    <InputBase
                        value={searchValue}
                        onChange={(e) => handleSearch(e.target.value)}
                        sx={{ ...styles.input, fontSize: isMobile ? "1.1rem" : "1rem" }}
                        placeholder="Search Cards & Tags"
                        inputProps={{ "aria-label": "search" }}
                        endAdornment={
                            searchValue && <Clear onClick={handleClear} sx={styles.close} />
                        }
                    />
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pl: 0.2,
                    }}
                >
                    <ReferencesSwitch />
                    {!hideCloseButton && (
                        <IconButton onClick={handleDrawerClose} size="small">
                            <ChevronLeft />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    wrapper: {
        position: "relative",
        borderRadius: 0.3,
        backgroundColor: (theme) => theme.palette.action.hover,
        "&:hover": {
            backgroundColor: (theme) => theme.palette.action.disabledBackground,
        },
        flex: 1,
    },
    icon: {
        paddingLeft: 0.9,
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: (theme) => theme.palette.grey[400],
    },
    input: {
        color: "inherit",
        paddingLeft: (theme) => `calc(1em + ${theme.spacing(2.4)})`,
        width: "100%",
    },
    close: {
        mr: 1,
        cursor: "pointer",
    },
}

export const TagsTreeSearch = memo(TagsTreeSearchComponent, () => true)
