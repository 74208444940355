import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material"
import { Spinner } from "@recall/common"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { summaryUsageService } from "repositories/usageRepository"
import { RootState } from "storage/redux/rootReducer"

export const UsageLimits = () => {
    const [usageLimits, setUsageLimits] = useState({
        cardsScraped: 0,
        isLoading: true,
    })

    const uid = useSelector((state: RootState) => state.user.uid)

    const getGeneratedSummariesCount = async (): Promise<number> => {
        return new Promise((resolve) =>
            setTimeout(async () => {
                // This is delayed because this fn is triggered before fn for increasing scraping usage, item gets saved and this is immediately triggered
                const scrapingUsage = await summaryUsageService.getUsage(uid)
                resolve(scrapingUsage)
            }, 1000)
        )
    }

    const getUsageLimits = async () => {
        const cardsScraped = await getGeneratedSummariesCount()

        setUsageLimits({ cardsScraped, isLoading: false })
    }

    useEffect(() => {
        getUsageLimits()
    }, [])

    return (
        <Box display="flex" flexDirection="column">
            <Box>
                <Tooltip
                    title={
                        <Typography>
                            Using Recall Lite, you can generate up to {summaryUsageService.limit}{" "}
                            free AI summaries.
                        </Typography>
                    }
                >
                    <Typography variant="caption" sx={styles.text} display="flex" gap={1}>
                        Generated summaries:{" "}
                        <Box display="flex" alignItems="center">
                            {usageLimits.isLoading ? (
                                <Box component="span" position="relative">
                                    <Spinner size={10} />
                                </Box>
                            ) : (
                                `${Math.min(usageLimits.cardsScraped, summaryUsageService.limit)}/${
                                    summaryUsageService.limit
                                }`
                            )}
                        </Box>
                    </Typography>
                </Tooltip>
            </Box>
        </Box>
    )
}

const styles: Record<string, SxProps<Theme>> = {
    text: {
        cursor: "pointer",
    },
}
