import { Add, ArrowDropDown } from "@mui/icons-material"
import {
    Backdrop,
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Link,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Portal,
    SxProps,
    Theme,
    Typography,
} from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import {
    OnboardingStepsEnum,
    WIKIPEDIA,
    checkIsUrl,
    createEmptyItem,
    getTheme,
    languages,
    libraryRepository,
    posthogService,
    sentry,
    tutorials,
} from "@recall/common"
import { PdfUploadModal } from "components/shared/modals/PdfUploadModal"
import { RERENDER_TAGS_EVENT } from "components/shared/tags/hooks/useGroupedTags"
import {
    COMPLETE_TUTORIAL_EVENT,
    CREATE_BLANK_CARD_CLICKED,
    SEARCH_SHORTCUT_USED,
} from "constants/events"
import { SAVE_LIB_SUMMARY_PATH, SETTINGS_PATH, SHARE_TARGET_PATH } from "constants/routes"
import { useOpenItem } from "hooks/items/useOpenItem"
import { useIsMobile } from "hooks/useIsMobile"
import { useIsNative } from "hooks/useIsNative"
import useSearchItems, { Result } from "hooks/useSearchItems"
import { useIsDarkMode } from "hooks/useThemeMode"
import isHotkey from "is-hotkey"
import { uniq } from "lodash"
import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { captureEventService } from "services/captureEventService"
import { itemAPI } from "storage/api/items/Item"
import { SET_IMPORT_STATE, SET_LOADING } from "storage/redux/app/actionTypes"
import { importEnum } from "storage/redux/app/types"
import { RootState } from "storage/redux/rootReducer"
import { SET_SEARCH_TERM, TUTORIAL_COMPLETE } from "storage/redux/user/actionTypes"
import { setOnboarding } from "storage/redux/user/actions"
import { ItemModel } from "storage/watermelon/models"
import { itemRepository } from "storage/watermelon/repository"
import { SearchResults } from "../../../shared/SearchResults/SearchResults"
import SearchInputComp from "../../../shared/inputs/SearchInput"
import { useOpenOnboarding } from "../Onboarding/hooks/useOpenOnboarding"
import { ShortcutInfo } from "./ShortcutInfo"

export const OPEN_SEARCH_CUSTOM_EVENT = "open-search-custom-event"

export const AddButton = memo(() => {
    const db = useDatabase()
    const history = useHistory()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [isPdfUploadOpen, setIsPdfUploadOpen] = useState(false)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const ref = useRef<HTMLElement | null>(null)
    const searchTerm = useSelector((state: RootState) => state.user.searchTerm)
    const [loading, results] = useSearchItems(searchTerm, { isSummarizationEnabled: true })
    const isMobile = useIsMobile()
    const isNative = useIsNative()
    const container = useRef(null)
    const isDarkMode = useIsDarkMode()
    const onboarding = useSelector((state: RootState) => state.user.onboarding)
    const language = useSelector((state: RootState) => state.user.searchLanguage)
    const addButtonRef = useRef<HTMLDivElement>(null)

    const { openItem } = useOpenItem()

    const { closeOnboarding } = useOpenOnboarding()

    useEffect(() => {
        const handleListenShortcut = (e: KeyboardEvent) => {
            if (isHotkey("cmd+k", e) || isHotkey("ctrl+k", e)) {
                posthogService.captureEvent(SEARCH_SHORTCUT_USED)
                handleOpenSearch()
            }
        }

        document.addEventListener(OPEN_SEARCH_CUSTOM_EVENT, handleOpenSearch)
        document.addEventListener("keydown", handleListenShortcut)

        return () => {
            document.removeEventListener("keydown", handleListenShortcut)
            document.removeEventListener(OPEN_SEARCH_CUSTOM_EVENT, handleOpenSearch)
        }
        // eslint-disable-next-line
    }, [])

    const setSearchTerm = (searchTerm: string) => {
        dispatch({ type: SET_SEARCH_TERM, payload: searchTerm })
    }

    const handleSelectResult = async (result: Result) => {
        handleClose()

        if (result === null) return null
        const item = await fetchItem(result)
        if (!item) return null
        const tagsCount = await item.tags.count
        if (tagsCount) {
            document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
        }

        closeOnboarding()
        openItem(item)
    }

    const finishCreateCardInSearchOnboarding = () => {
        if (
            onboarding?.finished &&
            !onboarding?.finished?.includes(OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH)
        )
            dispatch(
                setOnboarding({
                    ...onboarding,
                    finished: uniq([
                        ...onboarding.finished,
                        OnboardingStepsEnum.CREATE_CARD_IN_APP_SEARCH,
                    ]),
                })
            )
    }

    const generateSummary = async (name: string) => {
        const libraryItem = await libraryRepository.getLibraryItemByUrlOrVideoId(name)
        if (libraryItem) {
            history.push(`${SAVE_LIB_SUMMARY_PATH}?id=${libraryItem.id}`)
        } else {
            history.push(`${SHARE_TARGET_PATH}?url=${name}`)
        }
        finishCreateCardInSearchOnboarding()
        setSearchTerm("")
    }

    const fetchItem = async (result: Result): Promise<ItemModel | null> => {
        if (result.isGenerateSummaryOption) {
            await generateSummary(result.name)
            return
        }

        dispatch({ type: SET_LOADING, payload: true })
        try {
            if (result?.isCreateOption) {
                return await itemRepository.createFull(db, result)
            } else if (result.isSaved) {
                return await itemRepository.get(db, result.id)
            } else {
                const item = await itemRepository.getBySources(db, result.sources, true)
                if (item) return item

                const wikipediaSource = result.sources.find((source) => source.name === WIKIPEDIA)
                if (wikipediaSource) {
                    const item = await itemAPI.getAndSaveWikipedia(
                        db,
                        wikipediaSource.identifier,
                        result.language
                    )
                    if (item) await item.setIsExpanded(true)
                    return item
                }
            }
        } catch (e) {
            sentry.captureException(e)
        } finally {
            dispatch({ type: SET_LOADING, payload: false })
            finishCreateCardInSearchOnboarding()
        }

        return null
    }

    const completeTutorial = () => {
        dispatch({ type: TUTORIAL_COMPLETE, payload: tutorials.ITEMS_INTRO })
        captureEventService.capture(COMPLETE_TUTORIAL_EVENT, {
            tutorialName: tutorials.ITEMS_INTRO,
        })
    }

    const handleClose = () => {
        setOpen(false)
        setSearchTerm("")
    }

    const handleOpenSearch = () => {
        completeTutorial()
        setOpen(true)
    }

    const handleSubmit = async () => {
        if (checkIsUrl(searchTerm)) {
            await generateSummary(searchTerm)
        }
    }

    const handleToggleDropdown = async () => {
        setIsDropdownOpen((prev) => !prev)
    }

    const handleCloseDropdown = (event: Event) => {
        if (addButtonRef.current && addButtonRef.current.contains(event.target as HTMLElement)) {
            return
        }

        setIsDropdownOpen(false)
    }

    const handleCreateBlank = async (e) => {
        e.stopPropagation()
        posthogService.captureEvent(CREATE_BLANK_CARD_CLICKED)
        const item = await itemRepository.createFull(db, createEmptyItem())
        openItem(item)
    }

    const handleOpenImportBookmarks = () => {
        dispatch({ type: SET_IMPORT_STATE, payload: importEnum.OPENED })
    }

    const handleOpenUploadPdf = () => {
        setIsPdfUploadOpen(true)
    }

    return (
        <>
            <ButtonGroup
                ref={addButtonRef}
                variant="contained"
                aria-label="Button group with a nested menu"
                size={isMobile ? "large" : "small"}
            >
                <Button
                    onClick={handleOpenSearch}
                    sx={styles.addButton}
                    color="primary"
                    variant="contained"
                    startIcon={<Add />}
                >
                    New
                </Button>
                <Button
                    color="primary"
                    sx={{ px: 0, minWidth: `${isMobile ? "40px" : "10px"} !important` }}
                    onClick={handleToggleDropdown}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                placement="bottom-end"
                open={isDropdownOpen}
                anchorEl={addButtonRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper sx={{ py: 0 }}>
                            <ClickAwayListener onClickAway={handleCloseDropdown}>
                                <MenuList id="split-button-menu" sx={{ py: 0 }} autoFocusItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleOpenSearch}>
                                        From URL
                                    </MenuItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleCreateBlank}>
                                        Empty Card
                                    </MenuItem>
                                    <MenuItem sx={{ px: 1 }} onClick={handleOpenUploadPdf}>
                                        Upload PDF
                                    </MenuItem>
                                    {!isMobile && !isNative && (
                                        <MenuItem
                                            onClick={handleOpenImportBookmarks}
                                            sx={{ px: 1 }}
                                        >
                                            Import Bookmarks
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            {isPdfUploadOpen && (
                <PdfUploadModal
                    closeModal={() => {
                        setIsPdfUploadOpen(false)
                    }}
                />
            )}
            {open && (
                <Portal container={container.current}>
                    <Backdrop open={open} sx={styles.backdrop}>
                        <Box sx={styles.searchWrapper} ref={ref}>
                            {!isMobile && <ShortcutInfo size="medium" />}
                            {open && (
                                <>
                                    <Typography sx={styles.instructions}>
                                        Paste a URL to summarize or type to search for anything you
                                        want to recall later
                                    </Typography>
                                    <SearchInputComp
                                        value={searchTerm}
                                        onClose={handleClose}
                                        onChange={setSearchTerm}
                                        onPressEnter={handleSubmit}
                                    />
                                    <SearchResults
                                        open={open}
                                        value={searchTerm}
                                        anchorEl={ref.current}
                                        onClose={handleClose}
                                        loading={loading}
                                        results={results}
                                        onSelectResult={handleSelectResult}
                                    />
                                </>
                            )}
                            <Typography variant="caption" sx={styles.language}>
                                You are searching in{" "}
                                <b>{languages?.[language]?.label || "English"}</b>
                                <Link
                                    color={isDarkMode ? "primary" : "secondary"}
                                    component="div"
                                    sx={{ cursor: "pointer" }}
                                    onMouseDown={() => history.push(SETTINGS_PATH)}
                                >
                                    Change Language
                                </Link>
                            </Typography>
                        </Box>
                    </Backdrop>
                </Portal>
            )}
        </>
    )
})

const theme = getTheme("dark")

const styles: Record<string, SxProps<Theme>> = {
    instructions: {
        textAlign: "center",
        color: (theme) =>
            theme.palette.mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.common.white,
        fontWeight: 600,
    },
    backdrop: {
        width: "100vw",
        height: "100vh",
        zIndex: theme.zIndex.drawer + 1,
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0,0,0,0.7)",
    },

    searchWrapper: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
        position: "absolute",
        top: { xs: "16px", md: "10%" },
        left: "50%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up("sm")]: {
            width: "400px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: "0 15px",
        },
    },
    fab: {
        position: "fixed",
        bottom: 16,
        right: 16,
    },
    language: {
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 100%)",
        width: "100%",
        textAlign: "center",
        opacity: 0.9,
        color: (theme) =>
            theme.palette.mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.common.white,
    },
    addButton: {
        fontSize: 16,
        p: 0,
        pl: 1,
        pr: 1.5,
        m: 0,
    },
}
