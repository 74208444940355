import { Close } from "@mui/icons-material"
import {
    Box,
    Button,
    Dialog,
    IconButton,
    LinearProgress,
    Slide,
    SxProps,
    Theme,
    Typography,
    alpha,
} from "@mui/material"
import { OnboardingStepsEnum, checkExtensionStatus } from "@recall/common"
import { FC, forwardRef, memo } from "react"
import { useDispatch, useSelector } from "react-redux"

import ReplayIcon from "@mui/icons-material/Replay"
import { TransitionProps } from "@mui/material/transitions"
import LogoDark from "assets/svg/logo_text_full_dark.svg"
import LogoLight from "assets/svg/logo_text_full_light.svg"
import { useIsDarkMode } from "hooks/useThemeMode"
import { userRepository } from "repositories/userRepository"
import { RootState } from "storage/redux/rootReducer"
import { SET_ONBOARDING, TUTORIAL_RESTART } from "storage/redux/user/actionTypes"
import { useCompleteOnboarding } from "../Tutorial/hooks/useCompleteOnboarding"
import { OnboardingBanner } from "./OnboardingBanner"
import { OnboardingSections } from "./OnboardingSections"
import { useOnboardingProgress } from "./hooks/useOnboardingProgress"
import { useOpenOnboarding } from "./hooks/useOpenOnboarding"
import { useSteps } from "./hooks/useSteps"

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />
})

const OnboardingComponent: FC = () => {
    const onboardingSections = useSteps()
    const { stepsProgress, isCompleted } = useOnboardingProgress()
    const finishedOnboarding = useSelector((state: RootState) => state.user.onboarding.finished)
    const uid = useSelector((state: RootState) => state.user.uid)
    const { isOnboardingOpen, openOnboarding, closeOnboarding } = useOpenOnboarding()
    const isDarkMode = useIsDarkMode()

    useCompleteOnboarding()

    const dispatch = useDispatch()

    const handleResetOnboarding = async () => {
        const { isExtensionInstalled } = await checkExtensionStatus()

        const finished = finishedOnboarding.filter((onboarding) =>
            [OnboardingStepsEnum.CREATE_ACCOUNT, OnboardingStepsEnum.VERIFY_ACCOUNT].includes(
                onboarding
            )
        )

        if (isExtensionInstalled) finished.push(OnboardingStepsEnum.INSTALL_EXTENSION)

        const onboarding = { skipped: [], finished }
        closeOnboarding()
        dispatch({ type: TUTORIAL_RESTART })
        dispatch({
            type: SET_ONBOARDING,
            payload: onboarding,
        })

        await userRepository.upsertUser(uid, {
            onboarding,
            tutorial: { completed: [] },
        })
    }

    return isOnboardingOpen ? (
        <Dialog
            sx={styles.dialog}
            fullScreen
            open
            onClose={closeOnboarding}
            TransitionComponent={Transition}
        >
            <Box height="100%">
                <LinearProgress
                    color={"primary"}
                    variant="determinate"
                    value={stepsProgress * 100}
                    sx={{ height: 6 }}
                />
                <Box sx={styles.container}>
                    <Box
                        pt={1}
                        pb={0.5}
                        pl={1.6}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box onClick={closeOnboarding} sx={{ cursor: "pointer" }}>
                            <Box
                                component="img"
                                alt="recall logo"
                                height={19}
                                src={isDarkMode ? LogoDark : LogoLight}
                            />
                        </Box>
                        <IconButton onClick={closeOnboarding} sx={{ p: 0.6 }}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box sx={styles.header} pt={{ xs: 2, md: 0 }}>
                        <Typography variant="h4" mb={1}>
                            {isCompleted ? "Your onboarding is complete" : "Recall Onboarding"}
                        </Typography>
                        {!isCompleted && (
                            <Typography variant="body1">
                                Follow these steps to get started
                            </Typography>
                        )}
                    </Box>

                    <OnboardingSections sections={onboardingSections} />

                    <Box display="flex" alignItems="center" justifyContent="center" mt={1}>
                        <Button
                            startIcon={<ReplayIcon />}
                            sx={{
                                color: (theme) => alpha(theme.palette.text.primary, 0.7),
                                "&:hover": {
                                    color: "text.primary",
                                },
                            }}
                            onClick={handleResetOnboarding}
                        >
                            Restart
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    ) : (
        <OnboardingBanner onClick={openOnboarding} />
    )
}

const styles: Record<string, SxProps<Theme>> = {
    dialog: {
        backgroundColor: (theme) => `${theme.palette.background.default} !important`,
        minHeight: "100vh",
    },
    container: {
        p: 2,
        pt: 0,
        minHeight: "100%",
        backgroundColor: (theme) => `${theme.palette.background.default} !important`,
    },
    backText: {
        color: (theme) => theme.palette.primary.main,
        display: "flex",
        alignItems: "center",
        mb: 3,
    },
    header: {
        position: "relative",
        textAlign: "center",
    },
    close: {
        display: "flex",
        justifyContent: "flex-end",
    },
}

export const Onboarding = memo(OnboardingComponent)
