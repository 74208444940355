import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import TreeView from "@mui/lab/TreeView"
import { Box, SxProps, Theme, alpha } from "@mui/material"
import { useDatabase } from "@nozbe/watermelondb/react"
import { useIsMobile } from "hooks/useIsMobile"
import { map, orderBy } from "lodash"
import { FC, memo, useEffect, useState } from "react"
import { ROOT_TAG_ID, tagRepository } from "storage/watermelon/repository"
import { ConfirmModal } from "../modals/ConfirmModal"
import { TagUpdateModal } from "./TagUpdateModal"
import { TagsTreeEmptyState } from "./TagsTreeEmptyState"
import { TagsTreeItem } from "./TagsTreeItem"
import { TagsTreeSearch } from "./TagsTreeSearch"
import { TagsTreeSkeleton } from "./TagsTreeSkeleton"
import { TagsTreeUntaggedItems } from "./TagsTreeUntaggedItems"
import { useExpandedTags } from "./hooks/useExpandedTags"
import { RERENDER_TAGS_EVENT, Tag, useGroupedTags } from "./hooks/useGroupedTags"
import { useSelectedTags } from "./hooks/useSelectedTags"

interface Props {
    selectedItemId?: string
    handleClickItem: (id: string) => void
    isSwipeable: boolean
}

const TagsTreeComponent: FC<Props> = ({ selectedItemId, handleClickItem, isSwipeable }) => {
    const db = useDatabase()
    const isMobile = useIsMobile()
    const [searchExpandedState, setSearchExpandState] = useState([])

    const { tags, searchText, isEmpty, setSearchText } = useGroupedTags()

    const { selectedTagIds, deselectTags, toggleSelectTag } = useSelectedTags(tags)
    const { expandedTags, handleChangeExpanded, expandTag } = useExpandedTags(tags)

    const [tagToDelete, setTagToDelete] = useState<Tag | null>(null)
    const [tagToUpdate, setTagToUpdate] = useState<Tag | null>(null)

    const [isDraggedOver, setIsDraggedOver] = useState(false)

    useEffect(() => {
        if (!searchText && searchExpandedState.length) {
            setSearchExpandState([])
        }
    }, [searchText])

    const rootTagChildren = tags?.[ROOT_TAG_ID]?.children
        ? orderBy(
              tags[ROOT_TAG_ID].children
                  .filter((child) => tags[child])
                  ?.map((child) => tags[child]),
              (tag) => tag?.name?.toLowerCase()
          )
        : []

    const handleToggle = (_: React.SyntheticEvent, nodeIds: string[]) => {
        if (searchText) {
            setSearchExpandState(nodeIds)
        } else {
            handleChangeExpanded(nodeIds)
        }
    }

    const expandedSearchedTags = searchExpandedState.length ? searchExpandedState : map(tags, "id")

    const handleDropTag = async (tagId: string) => {
        if (!tagId) return

        const childTag = await tagRepository.get(db, tagId)

        if (childTag.parent.id === ROOT_TAG_ID) return

        const existingTag = await tagRepository.getTagByNameAndParentId(
            db,
            childTag.name,
            ROOT_TAG_ID
        )

        if (existingTag) {
            await tagRepository.mergeTags(db, existingTag, childTag)
            return
        }

        await tagRepository.updateParent(db, ROOT_TAG_ID, tagId)
    }

    return (
        <>
            <Box overflow="hidden" pl={0.5} pb={1.5}>
                <TagsTreeSearch search={setSearchText} hideCloseButton={isSwipeable} />
            </Box>
            <Box
                sx={{
                    ...styles.tagsTree,
                    mb: 0,
                    background: (theme) => isDraggedOver && theme.palette.action.disabledBackground,
                }}
                onDragOver={(e) => {
                    e.preventDefault()
                    if (!isDraggedOver) setIsDraggedOver(true)
                }}
                onDragLeave={() => {
                    setIsDraggedOver(false)
                }}
                onDrop={async (e) => {
                    setIsDraggedOver(false)
                    const { tagId, isTag, itemId } = JSON.parse(
                        e.dataTransfer.getData("application/json")
                    )

                    if (isTag) {
                        await handleDropTag(tagId)
                    } else {
                        await tagRepository.detach(db, tagId, itemId)
                    }
                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                }}
            >
                {isEmpty ? (
                    <TagsTreeEmptyState />
                ) : (
                    <>
                        {tagToDelete && (
                            <ConfirmModal
                                isOpen
                                onClose={() => setTagToDelete(null)}
                                title="Delete tag"
                                description={`Are you sure you want to delete tag ${tagToDelete.name}? All associations to this tag will be removed.`}
                                onConfirm={async () => {
                                    deselectTags([tagToDelete.id])
                                    await tagRepository.deleteTag(db, tagToDelete.id)
                                    document.dispatchEvent(new CustomEvent(RERENDER_TAGS_EVENT))
                                    setTagToDelete(null)
                                }}
                                confirmText="Delete"
                                closeText="Cancel"
                            />
                        )}
                        {tagToUpdate && (
                            <TagUpdateModal
                                name={tagToUpdate.name}
                                parentId={tagToUpdate.parentId}
                                tagId={tagToUpdate.id}
                                onClose={() => {
                                    setTagToUpdate(null)
                                }}
                            />
                        )}

                        <Box pr={1}>
                            <TreeView
                                defaultCollapseIcon={
                                    <ChevronRightIcon
                                        sx={{
                                            ...styles.collapseIcon,
                                            fontSize: isMobile ? "24px !important" : "inherit",
                                        }}
                                    />
                                }
                                defaultExpandIcon={
                                    <ChevronRightIcon
                                        sx={{
                                            ...styles.expandIcon,
                                            fontSize: isMobile ? "24px !important" : "inherit",
                                        }}
                                    />
                                }
                                expanded={searchText ? expandedSearchedTags : expandedTags}
                                onNodeToggle={handleToggle}
                            >
                                {tags[ROOT_TAG_ID] ? (
                                    rootTagChildren.map((tag) => (
                                        <TagsTreeItem
                                            key={tag.id}
                                            selectedItemId={selectedItemId}
                                            toggleSelect={toggleSelectTag}
                                            selectedTagIds={selectedTagIds}
                                            tags={tags}
                                            tag={tag}
                                            setTagToDelete={setTagToDelete}
                                            setTagToUpdate={setTagToUpdate}
                                            handleClickItem={handleClickItem}
                                            expandTag={expandTag}
                                            searchText={searchText}
                                        />
                                    ))
                                ) : (
                                    <TagsTreeSkeleton />
                                )}
                                <TagsTreeUntaggedItems
                                    isSearched={Boolean(searchText)}
                                    searchText={searchText}
                                    handleClickItem={handleClickItem}
                                />
                            </TreeView>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}

export const TagsTree = memo(TagsTreeComponent)

const styles: Record<string, SxProps<Theme>> = {
    tagsTree: {
        overflow: "auto",
        overflowY: "scroll",
        height: "100%",
        pl: 1,
        "&::-webkit-scrollbar": {
            height: 0,
            width: "6px",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
        },

        "&:hover::-webkit-scrollbar-thumb": {
            background: (theme) => alpha(theme.palette.grey[500], 0.2),
            "&:hover": {
                background: (theme) => alpha(theme.palette.grey[500], 0.6),
            },
        },
    },
    expandIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
    collapseIcon: {
        opacity: 0.6,
        color: (theme) => theme.palette.grey[500],
        transform: "rotate(90deg)",
        transition: "transform 0.1s linear",
        "&:hover": {
            opacity: 1,
        },
    },
}
